import {SET_INVITE_INFO, SET_INVITE_EXPIRE} from '../type'

export default {
  namespaced: true,
  state: {
    info: {}, // 邀请信息
    expiredAt: '' // 邀请信息过期时间
  },

  mutations: {
    [SET_INVITE_INFO](state, payload) {
      state.info = payload
    },
    [SET_INVITE_EXPIRE](state, payload) {
      state.expiredAt = payload
    }
  },

  actions: {
    updateInviteInfo({commit}, payload) {
      commit(SET_INVITE_INFO, payload.info || {})
      commit(SET_INVITE_EXPIRE, payload.expiredAt || Date.now())
    }
  }
}
