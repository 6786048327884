export default {
  userInfo: state => state.user.userInfo,
  inviteInfo: state => ({...state.invite.info, expiredAt: state.invite.expiredAt}),
  realNameAuth: state => state.user.realNameAuth,
  contractInfo: state => state.contract.info,
  selectedTemplateId: state => state.contract.selectedTemplateId,
  templateData: state => state.contract.templateData,
  bindInfo: state => state.user.bindInfo,
  fadadaAuthStatus: state => state.user.fadadaAuthStatus
}
