import {SET_CONTRACT_INFO, SET_TEMPLATE_ID, SET_TEMPLATE_DATA} from '../type'

export default {
  namespaced: true,
  state: {
    info: {
      name: '', // 真实姓名
      idCard: '', // 身份证号
      phone: '', // 手机号
      liveId: '', // 房间号
      platform: '' // 房间号
    }, // 合同接受人信息
    selectedTemplateId: '', // 模板id
    templateData: {} // 模板数据
  },

  mutations: {
    [SET_CONTRACT_INFO](state, payload) {
      state.info = payload
    },
    [SET_TEMPLATE_ID](state, payload) {
      state.selectedTemplateId = payload
    },
    [SET_TEMPLATE_DATA](state, payload) {
      state.templateData = payload
    }
  },

  actions: {
    // 更新模板id
    updateTemplateId({commit}, payload) {
      commit(SET_TEMPLATE_ID, payload || '')
    },

    // 更新合同接收人信息
    updateInfo({commit}, payload) {
      commit(SET_CONTRACT_INFO, payload || {})
    },

    // 删除合同接收人信息
    deleteInfo({commit}) {
      commit(SET_CONTRACT_INFO, {})
    },

    // 更新模板数据
    updateTemplateData({commit}, payload) {
      commit(SET_TEMPLATE_DATA, payload || {})
    }
  }
}
