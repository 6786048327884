import Vue from 'vue'
import Router from 'vue-router'
import routers from './routers'
import storage from '@/utils/storage'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

// vue-route v3.1.0后会出现的报错 参考地址如下
// https://github.com/vuejs/vue-router/issues/2881
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

Vue.use(Router)

const createRouter = () => {
  return new Router({
    mode: 'history',
    // 使用<keep-alive>，scrollBehavior才能生效。
    scrollBehavior: () => ({
      y: 0
    }),
    routes: routers
  })
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = ['/', '/customer', '/bind', '/service']

router.beforeEach(async (to, from, next) => {
  // 设置界面title
  const title = to.meta.title || '思凯服务号'
  document.title = title
  // start progress bar
  NProgress.start()
  // 判断是否有token
  const openid = storage.get('OPENID') || ''
  const token = storage.getToken() || ''
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (to.query.token) {
      storage.clear()
      storage.setToken(to.query.token)
      delete to.query.token
      next({...to, replace: true})
    } else if (token || openid) {
      next()
    } else {
      storage.clear()
      next({path: '/'})
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export default router
