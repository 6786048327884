import Vue from 'vue'

// sentry
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

// process.env.VUE_APP_ENV === 'production' && Sentry.init({
//   dsn: 'http://1b38148d9ef04ea8ae9a64ddaaf7c7ab@120.24.70.84:9000/2',
//   integrations: [new Integrations.Vue({Vue, attachProps: true})],
//   release: process.env.VUE_APP_RELEASE_VERSION
// })

// flexible
import 'amfe-flexible'
// global css
import '@/styles/index.less'

import App from './App'
import router from './router'
import store from './store'

import './vant.js'

import dayjs from 'dayjs'
Vue.prototype.$day = dayjs

// wx jssdk
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
