const TOKEN = 'ACCESSTOKEN'

const localStorage = typeof localStorage === 'object'
  ? localStorage
  : window.localStorage

export default {
  // 保存token
  setToken(token) {
    return localStorage.setItem(TOKEN, token)
  },

  // 获取token
  getToken() {
    return localStorage.getItem(TOKEN)
  },

  // 移除token
  removeToken() {
    return localStorage.removeItem(TOKEN)
  },

  // 数据存储
  set(key, value) {
    return localStorage.setItem(key, value)
  },

  // 数据读取
  get(key) {
    return localStorage.getItem(key)
  },

  // 移除数据
  remove(key) {
    return localStorage.removeItem(key)
  },

  // 移除所有存储信息
  clear() {
    localStorage.clear()
  }
}
