import Vue from 'vue'

import {
  Button,
  Col,
  Row,
  Grid,
  GridItem,
  List,
  Cell,
  Icon,
  Step,
  Steps,
  Dialog,
  DropdownMenu,
  DropdownItem,
  Search,
  Field,
  Form,
  Toast,
  NoticeBar,
  Popup,
  Area,
  Picker,
  DatetimePicker,
  Tab,
  Tabs,
  Empty,
  ImagePreview,
  Sticky,
  Radio,
  RadioGroup,
  Uploader,
  ActionSheet,
  SwipeCell,
  Overlay,
  Loading
} from 'vant'

Vue.use(Button)
Vue.use(Col)
Vue.use(Row)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(List)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Dialog)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Search)
Vue.use(Form)
Vue.use(Field)
Vue.use(NoticeBar)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Picker)
Vue.use(DatetimePicker)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Empty)
Vue.use(ImagePreview)
Vue.use(Sticky)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Uploader)
Vue.use(ActionSheet)
Vue.use(SwipeCell)
Vue.use(Overlay)
Vue.use(Loading)

Vue.prototype.$dialog = Dialog
Vue.prototype.$toast = Toast
