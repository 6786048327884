// app
export const UPDATE_I18N = 'UPDATE_I18N'
export const UPDATE_SIZE = 'UPDATE_SIZE'

// user
export const SET_USERINFO = 'SET_USERINFO'
export const SET_REALNAMEAUTH = 'SET_REALNAMEAUTH'
export const SET_BINDINFO = 'SET_BINDINFO'
export const SET_FADADA_STATUS = 'SET_FADADA_STATUS'

// invite
export const SET_INVITE_INFO = 'SET_INVITE_INFO'
export const SET_INVITE_EXPIRE = 'SET_INVITE_EXPIRE'

// contract
export const SET_CONTRACT_INFO = 'SET_CONTRACT_INFO'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA'
