import storage from '@/utils/storage'
import {
  SET_USERINFO,
  SET_REALNAMEAUTH,
  SET_BINDINFO,
  SET_FADADA_STATUS
} from '../type'

const state = {
  userInfo: {
    isDirectly: false, // 是否为直属 true 平台 false 分公司
    userType: '', // 当前选择的用户信息用户信息 userType BB BY G CMB CML CMS
    isLeader: false // 是否为组长
  },
  realNameAuth: {}, // 用户二要素实名认证
  bindInfo: {}, // 用户绑定时需要的信息
  fadadaAuthStatus: false // 法大大实名认证状态
}

const mutations = {
  [SET_USERINFO](state, values) {
    state.userInfo = values
  },
  [SET_REALNAMEAUTH](state, values) {
    state.realNameAuth = values
  },
  [SET_BINDINFO](state, values) {
    state.bindInfo = values
  },
  [SET_FADADA_STATUS](state, values) {
    state.fadadaAuthStatus = values
  }
}

const actions = {
  // 用户登录
  login({commit}, payload) {
    commit(SET_USERINFO, payload)
  },
  // user logout
  logout({commit}) {
    commit(SET_USERINFO, {})
    storage.clear()
  },
  // 更新实名认证信息
  updateAuth({commit}, payload) {
    payload.expiredAt = payload.expiredAt || Date.now()
    commit(SET_REALNAMEAUTH, payload)
  },
  // 更新绑定所需信息
  updateBindInfo({commit}, payload) {
    commit(SET_BINDINFO, payload || {})
  },
  // 更新用户法大大实名认证状态
  updateFadadaAuthStatus({commit}, payload) {
    commit(SET_FADADA_STATUS, payload)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
