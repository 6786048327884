import {UPDATE_I18N, UPDATE_SIZE} from '../type'

export default {
  namespaced: true,
  state: {
    i18n: 'zh', // 默认的语言
    size: 'medium' // element组件的尺寸
  },

  mutations: {
    [UPDATE_I18N](state, payload) {
      state.i18n = payload
    },
    [UPDATE_SIZE](state, payload) {
      state.size = payload
    }
  },

  actions: {
    updateI18N({commit}, payload) {
      commit(UPDATE_I18N, payload)
    },
    updateSize({commit}, payload) {
      commit(UPDATE_SIZE, payload)
    }
  }
}
