const routes = [
  {
    path: '/',
    component: () => import('@/views/RouterView'),
    children: [{
      path: '',
      component: () => import('@/views/index/index')
    }, {
      path: 'bind',
      component: () => import('@/views/index/bind')
    }, {
      path: 'home',
      component: () => import('@/views/index/home')
    }, {
      path: 'character',
      component: () => import('@/views/index/character')
    }, {
      path: 'clear',
      component: () => import('@/views/index/clear')
    }]
  },
  {
    path: '/anchor',
    component: () => import('@/views/RouterView'),
    children: [{
      path: 'follow',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/anchor/follow/index'),
        meta: {title: '跟播列表'}
      }, {
        path: 'info/:id',
        component: () => import('@/views/anchor/follow/info/index'),
        meta: {title: '主播跟播详情'}
      }, {
        path: 'poster',
        component: () => import('@/views/anchor/follow/poster'),
        meta: {title: '主播跟播海报'}
      }]
    }, {
      path: 'recommend',
      component: () => import('@/views/anchor/recommend/index'),
      meta: {title: '主播推荐位'}
    }, {
      path: 'account',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/anchor/account'),
        meta: {title: '主播开号列表'}
      }, {
        path: 'create',
        component: () => import('@/views/anchor/account/create'),
        meta: {title: '主播开号申请'}
      }, {
        path: 'info/:id',
        component: () => import('@/views/anchor/account/info'),
        meta: {title: '主播开号详情'}
      }]
    }]
  },
  {
    path: '/operator',
    component: () => import('@/views/RouterView'),
    children: [{
      path: 'branch',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/operator/branch/index'),
        meta: {title: '分公司日数据管理'}
      }, {
        path: 'info',
        component: () => import('@/views/operator/branch/info'),
        meta: {title: '分公司日数据汇报记录'}
      }]
    }, {
      path: 'platform',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/operator/platform/index'),
        meta: {title: '平台日数据管理'}
      }, {
        path: 'info',
        component: () => import('@/views/operator/platform/info'),
        meta: {title: '平台日数据汇报记录'}
      }]
    }]
  },
  {
    path: '/mission',
    component: () => import('@/views/RouterView'),
    children: [{
      path: '',
      component: () => import('@/views/mission/index'),
      meta: {title: '任务报告'}
    }, {
      path: 'info',
      component: () => import('@/views/mission/info'),
      meta: {title: '任务详情'}
    }]
  },
  {
    path: '/contract',
    component: () => import('@/views/RouterView'),
    children: [{
      path: '',
      component: () => import('@/views/contract/index'),
      meta: {title: '合同管理中心'}
    }, {
      path: 'result',
      component: () => import('@/views/contract/result'),
      meta: {title: '合同详情'}
    }, {
      path: 'create',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/contract/create/index'),
        meta: {title: '填写合同信息'}
      }, {
        path: 'chooseContractAnchor',
        component: () => import('@/views/contract/create/chooseContractAnchor'),
        meta: {title: '填写合同接收人'}
      }, {
        path: 'chooseConfirmationAnchor',
        component: () => import('@/views/contract/create/chooseConfirmationAnchor'),
        meta: {title: '填写确认单接收人'}
      }, {
        path: 'chooseTemplate',
        component: () => import('@/views/contract/create/chooseTemplate'),
        meta: {title: '选择合同模板'}
      }, {
        path: 'planTemplate',
        component: () => import('@/views/contract/create/planTemplate'),
        meta: {title: '填写分成方案'}
      }, {
        path: 'comfirmationTemplate',
        component: () => import('@/views/contract/create/comfirmationTemplate'),
        meta: {title: '填写确认单信息'}
      }]
    }, {
      path: 'review',
      component: () => import('@/views/RouterView'),
      children: [{
        path: '',
        component: () => import('@/views/contract/review/index'),
        meta: {title: '合同管理中心'}
      }, {
        path: 'info',
        component: () => import('@/views/contract/review/info'),
        meta: {title: '合同详情'}
      }]
    }, {
      path: 'fadada',
      component: () => import('@/views/contract/fadada'),
      meta: {title: '第三方校验'}
    }]
  },
  {
    path: '/service',
    component: () => import('@/views/service/index'),
    meta: {title: '营业情况详情'}
  },
  {
    path: '/resourceCenter',
    component: () => import('@/views/RouterView'),
    children: [{
      path: '',
      component: () => import('@/views/resourceCenter/index'),
      meta: {title: '物料中心'}
    }, {
      path: 'info',
      component: () => import('@/views/resourceCenter/info')
    }]
  },
  {
    path: '/invite',
    component: () => import('@/views/invite/index'),
    meta: {title: '邀请主播'}
  },
  {
    path: '/customer',
    component: () => import('@/views/customer/index'),
    meta: {title: '思凯文化传媒'}
  },
  {
    path: '*',
    redirect: '/customer'
  }
]

export default routes
